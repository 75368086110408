import * as React from 'react';
import { Checkbox, FormControlLabel, FormGroup, FormGroupProps } from '@mui/material';
import { observer } from 'mobx-react';

import { DictionaryStore } from '../../../../stores/dictionary-store';
import { IDictionaryItem } from '../../../../view-models';

export const FieldCheckboxGroup: React.ForwardRefExoticComponent<
  Omit<
    FormGroupProps & {
      url?: string;
      store?: DictionaryStore;
      value: Set<string | number>;
      isFieldHide?: (item: IDictionaryItem) => boolean;
      disabled?: boolean;
    },
    'ref'
  > &
    React.RefAttributes<unknown>
> = observer(
  React.forwardRef((props, ref) => {
    const { disabled, url, store, value, isFieldHide, ...restProps } = props;

    React.useEffect(() => {
      store?.fetchElements(url);
    }, [url, store]);

    return (
      <FormGroup {...restProps} ref={ref}>
        {store?.dictionaries
          .get(url ?? '')
          ?.filter((item) => !isFieldHide?.(item) ?? true)
          .map((item) => {
            const id = item?.id;
            const label = item?.name;
            const isChecked = value?.has(id) ?? false;

            return (
              <FormControlLabel
                key={id}
                control={<Checkbox checked={isChecked} name={`${id}`} />}
                label={label}
                disabled={disabled}
              />
            );
          })}
      </FormGroup>
    );
  })
);
