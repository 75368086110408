import * as React from 'react';

import { PurposeType } from '../../constants';

import { Card, ICardProps } from './card';

import './main.css';

const links: Omit<ICardProps, 'index'>[] = [
  {
    name: 'Приемка',
    href: '/orders',
  },
  {
    name: 'Оценка',
    href: `/packages/${PurposeType.ForExpertise}`,
  },
  {
    name: 'Съемка Гравировка',
    href: `/packages/${PurposeType.ForEngravingShooting}`,
  },
  {
    name: 'Сертификаты',
    href: '/sertificates',
  },
];

export const Main: React.FC = () => {
  return (
    <div className="main__cards">
      {links.map((item, index) => (
        <Card key={item.name} index={index + 1} {...item} />
      ))}
    </div>
  );
};
