import * as React from 'react';
import { useForm } from 'react-hook-form';
import { MobXProviderContext } from 'mobx-react';

import { Button, Form, FormButtons, FormFieldType } from '../../components';
import { Stores } from '../../stores';
import { ICredentials } from '../../view-models';

import './login.css';

export const Login: React.FC = () => {
  const { authStore: store } = React.useContext(MobXProviderContext) as Stores;

  const {
    clearErrors,
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setError,
    setValue,
  } = useForm<Partial<ICredentials>>({
    values: {
      // username: 'sgcuser',
      // password: 'password',
    },
  });

  const changeHandler = React.useCallback(
    (event: any) => {
      clearErrors();
      setValue(event.target.name, event.target.value, { shouldDirty: true });
    },
    [setValue, clearErrors]
  );

  const metadata = React.useMemo(
    () => [
      {
        name: 'username',
        placeholder: 'Логин',
        type: FormFieldType.TEXT,
        rules: { required: 'Обязательно к заполнению' },
        onChange: changeHandler,
      },
      {
        name: 'password',
        placeholder: 'Пароль',
        type: FormFieldType.PASSWORD,
        rules: { required: 'Обязательно к заполнению' },
        onChange: changeHandler,
      },
    ],
    [changeHandler]
  );

  React.useEffect(() => {
    store.logout();
  }, [store]);

  React.useEffect(() => {
    if (store.authError) {
      setError('username', { type: 'custom', message: store.authError?.message });
      setError('password', { type: 'custom', message: '' });
    }
  }, [store.authError]);

  return (
    <div className="wrapper">
      <div className="login">
        <div className="login__header">
          <h1 className="login__title">Авторизация</h1>
          <span className="login__description">Введите свой логин и пароль для входа в систему</span>
        </div>
        <Form className="login__form" metadata={metadata} control={control} errors={errors}>
          <FormButtons className="login__form-buttons">
            <Button.Contained type="submit" onClick={handleSubmit(store.login)} disabled={!isDirty}>
              Войти
            </Button.Contained>
          </FormButtons>
        </Form>
      </div>
    </div>
  );
};
