import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { MobXProviderContext, observer } from 'mobx-react';

import { FormWrapper, InfoBlock, PageWrapper, Panel, Tabs, UnderlinedHeader } from '../../components';
import { PackageState, packageStateMap, PurposeType, WorkItems } from '../../constants';
import { Stores } from '../../stores';
import { IDictionaryItem, IPackage } from '../../view-models';

import { Diagnostic, Engraving, Gemology, Measurement, Plotting, Shooting } from './tabs';

import './worksheet.css';

export const Worksheet: FC = observer(() => {
  const { packageStore: store } = useContext(MobXProviderContext) as Stores;
  const { packageId, purposeType } = useParams();
  const [selectedTab, setSelectedTab] = useState('' as IDictionaryItem['id']);

  useEffect(() => {
    if (packageId) {
      store.init(packageId, purposeType as PurposeType);
    }
  }, [store, packageId, purposeType]);

  const statusInfo = useMemo(
    () => [
      {
        label: 'Статус',
        value: `${packageStateMap.get(store.data?.state as PackageState) ?? ''}`,
      },
      {
        label: 'Дата передачи в работу',
        value: dayjs(store.data?.worksheet?.startDate).format('DD.MM.YYYY'),
      },
      {
        label: 'Количество, шт',
        value: `${store.data?.quantityFact ?? ''}`,
      },
      {
        label: 'Вес, ct',
        value: `${store.data?.weightFact ?? ''}`,
      },
    ],
    [store.data]
  );

  const tabs: Array<{
    id: string;
    name: string;
    isHide: (type: PurposeType, pack: IPackage | null) => boolean;
    component: FC;
  }> = useMemo(() => {
    return [
      {
        id: WorkItems.Diagnostics,
        name: 'Диагностика',
        isHide: (type: PurposeType) => type !== PurposeType.ForExpertise,
        component: Diagnostic,
      },
      {
        id: WorkItems.Measurement,
        name: 'Параметры',
        isHide: (type: PurposeType) => type !== PurposeType.ForExpertise,
        component: Measurement,
      },
      {
        id: WorkItems.Gemology,
        name: `Геммологическая\nэкспертиза`,
        isHide: (type: PurposeType) => type !== PurposeType.ForExpertise,
        component: Gemology,
      },
      {
        id: WorkItems.Plotting,
        name: 'Плоттинг',
        isHide: (type: PurposeType, pack: IPackage | null) =>
          type !== PurposeType.ForExpertise || !pack?.additionalWorkScope?.has('Plotting'),
        component: Plotting,
      },
      {
        id: WorkItems.Engraving,
        name: 'Гравировка',
        isHide: (type: PurposeType) => type !== PurposeType.ForEngravingShooting,
        component: Engraving,
      },
      {
        id: WorkItems.Shooting,
        name: 'Съемка',
        isHide: (type: PurposeType) => type !== PurposeType.ForEngravingShooting,
        component: Shooting,
      },
    ].filter((tab) => !tab.isHide(purposeType as PurposeType, store.data));
  }, [purposeType, store.data]);

  const Component: FC | undefined = tabs.find((tab) => tab.id === selectedTab)?.component;

  const onSelectTab = useCallback(
    (tab: IDictionaryItem) => {
      setSelectedTab(tab.id);
    },
    [setSelectedTab]
  );

  useEffect(() => {
    if (!Component) {
      setSelectedTab(tabs?.[0]?.id);
    }
  }, [Component, setSelectedTab, tabs]);

  return (
    <PageWrapper>
      <UnderlinedHeader>Рабочий лист пакета №{store.data?.number}</UnderlinedHeader>
      <FormWrapper>
        <InfoBlock data={statusInfo} />
        <Panel className="worksheet__panel">
          <Tabs data={tabs} selectedId={selectedTab} onSelect={onSelectTab} />
          {Component && <Component />}
        </Panel>
      </FormWrapper>
    </PageWrapper>
  );
});
