import { IJSONPatchOperation, JSONPatchOperations } from './models';

/**
 * Преобразовывает объект с изменениями в JSON Patch объект
 */
export function toJSONPatch(json: any, op: JSONPatchOperations = 'replace'): IJSONPatchOperation[] {
  const jsonPatch: IJSONPatchOperation[] = [];

  for (const key of Object.keys(json)) {
    if ([null, undefined, ''].includes(json[key])) {
      jsonPatch.push({ op: 'remove', path: `/${key}` });
    } else {
      jsonPatch.push({ op, path: `/${key}`, value: json[key] });
    }
  }

  return jsonPatch;
}
