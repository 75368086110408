import { PackageType, PurposeType } from '../constants';
import { IApi } from '../utils';
import { IPackage } from '../view-models';

export class MeasurementService {
  public constructor(private api: IApi) {}

  public async get(packageId: string, purposeType: PurposeType): Promise<any> {
    const res = await this.api.get({
      url: `package/${packageId}/${purposeType}`,
    });

    return res.body?.worksheet?.workItems?.measurement;
  }

  public async update(pack: IPackage, body: any) {
    //todo решить проблему с числовым input-ом
    const data = Object.entries(body).reduce((prev, [key, value]) => ({ ...prev, [key]: Number(value) }), {});

    await this.api.put({
      url: pack.type === PackageType.SingleStone ? 'worksheet/measure-singlestone' : 'worksheet/measure-multiplestones',
      body: {
        number: pack.number,
        ...data,
      },
    });
  }
}
