export class HttpError extends Error {
  public static predicate(status: number, body: any): boolean {
    return status >= 400;
  }

  public constructor(
    private status: number,
    private body: any,
    message?: string
  ) {
    super(message ?? `${status} Http error`);
    // http://stackoverflow.com/a/41429145
    Object.setPrototypeOf(this, HttpError.prototype);
  }

  public get statusCode(): number {
    return this.status;
  }

  public get errorBody(): any {
    return this.body;
  }
}
