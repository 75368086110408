import { KeycloakAuthError } from './keycloak-auth-error';

/**
 * Ошибка пароль просрочен
 */
export class PasswordExpiredError extends KeycloakAuthError {
  public static predicate(status: number, body: any): boolean {
    return body?.error === 'password_expired';
  }

  public constructor(status: number, body: any) {
    super(status, body);
    Object.setPrototypeOf(this, PasswordExpiredError.prototype);
  }
}
