import { createTheme } from '@mui/material/styles';

export const modalTheme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        backdrop: {
          backgroundColor: 'rgba(204, 204, 204, 0.7)',
        },
      },
    },
  },
});
