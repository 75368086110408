import { createTheme, tableRowClasses } from '@mui/material';

export const WhiteGreyTableTheme = createTheme({
  palette: {
    primary: {
      main: 'rgba(0,0,0,0)',
      contrastText: '#333333',
    },
    secondary: {
      main: 'rgba(0, 0, 0, 0.05)',
      contrastText: '#333333',
    },
  },
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            color: '#333333',
          },
          [`&.${tableRowClasses.selected}`]: {
            backgroundColor: '#877358',
            color: '#ffffff',
          },
          [`&.${tableRowClasses.selected}:hover`]: {
            backgroundColor: '#877358',
            color: '#ffffff',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: 'inherit',
          padding: '10px',
          fontSize: '14px',
          lineHeight: '19px',
          color: 'inherit',
          borderBottom: 'none',
        },
        head: {
          backgroundColor: '#ffffff',
        },
      },
    },
  },
});
