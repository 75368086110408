import { HttpError } from '../../../utils';

/**
 * Базовая ошибка аутентификации keycloak
 */
export class KeycloakAuthError extends HttpError {
  public static predicate(status: number, body: any): boolean {
    return body?.error && body?.error_description;
  }

  public constructor(status: number, body: any) {
    super(status, body);
    Object.setPrototypeOf(this, KeycloakAuthError.prototype);
  }

  public get code(): string {
    return this.errorBody.error;
  }

  public get displayMessage(): string {
    return this.errorBody.error_description;
  }
}
