import * as React from 'react';
import { observer } from 'mobx-react';

import { useConfirm } from '../../utils';

import { Modal } from './modal';

export const ConfirmationDialog: React.FunctionComponent = observer(() => {
  const { state, ...rest } = useConfirm();

  return state.isShow ? <Modal {...rest} {...state} /> : null;
});
