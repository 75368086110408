import { KeycloakAuthError } from './keycloak-auth-error';

/**
 * Ошибка пользователь заблокирован при превышении лимита неудачных попыток входа
 */
export class UserPermanentlyLockedOutError extends KeycloakAuthError {
  public static predicate(status: number, body: any): boolean {
    return body?.error === 'user_permanently_locked_out';
  }

  public constructor(status: number, body: any) {
    super(status, body);
    Object.setPrototypeOf(this, UserPermanentlyLockedOutError.prototype);
  }
}
