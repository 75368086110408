import * as React from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { FormControl, FormHelperText } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import * as lodash from 'lodash';

import { DictionaryStore } from '../../../stores/dictionary-store';
import { FieldOptions, IDictionaryItem } from '../../../view-models';
import { FieldType } from '../field-type';

import { FieldCheckboxGroup } from './fields/field-checkbox';
import { FieldPassword } from './fields/field-password';
import { FieldRadio } from './fields/field-radio';
import { FieldSelect } from './fields/field-select';
import { FieldText } from './fields/field-text';

import './field.css';

export interface IFieldProps extends Pick<ControllerProps, 'control' | 'rules' | 'disabled'> {
  className?: string;
  description?: string;
  errors?: FieldErrors<Record<string, any>>;
  label?: string;
  name: string;
  options?: FieldOptions;
  placeholder?: string;
  store?: DictionaryStore;
  type?: FieldType;
  url?: string;
  isFieldHide?: (item: IDictionaryItem) => boolean;
}

export const Field: React.FC<IFieldProps> = (props) => {
  const { control, label, name, rules, type, errors, ...restProps } = props;

  return (
    <Controller
      key={name}
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        const currentError = lodash.get(errors, name);

        const fieldProps = {
          ...field,
          ...restProps,
          error: !!currentError,
          required: !!rules?.required,
        };

        let component;

        switch (type) {
          case FieldType.DATE: {
            let value = field.value;
            if (value && !dayjs.isDayjs(value)) {
              value = dayjs(value);
            }

            component = (
              <DatePicker
                {...fieldProps}
                value={value}
                slotProps={{
                  textField: fieldProps,
                }}
                slots={{
                  openPickerIcon: () => <CalendarMonthOutlinedIcon classes={{ root: 'field__calendar' }} />,
                }}
              />
            );
            break;
          }

          case FieldType.RADIO: {
            component = <FieldRadio {...lodash.omit(fieldProps, 'error')} />;
            break;
          }

          case FieldType.CHECKBOX_GROUP: {
            component = <FieldCheckboxGroup {...lodash.omit(fieldProps, 'error')} />;
            break;
          }

          case FieldType.SELECT: {
            component = <FieldSelect {...fieldProps} />;
            break;
          }

          case FieldType.PASSWORD: {
            component = <FieldPassword {...fieldProps} />;
            break;
          }

          default: {
            component = <FieldText {...fieldProps} />;
          }
        }

        return (
          <FormControl required={!!rules?.required} error={!!currentError}>
            {component}
            {currentError && <FormHelperText className="field__helper-text">{currentError?.message as string}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};
