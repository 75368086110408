import { action, computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';

import { Services } from '../services';
import { IOrder } from '../view-models';

export class OrdersListStore {
  @observable public data: IObservableArray<IOrder> = observable([]);
  @observable private selectedOrderId: string | null = null;
  @observable public isLoading: boolean = false;

  public constructor(private services: Services) {
    makeObservable(this);
  }

  @action
  public init = async (search?: string): Promise<void> => {
    const res = await (search ? this.services.orders.getBySearch(search) : this.services.orders.get());
    runInAction(() => {
      this.data.replace(res);
    });
  };

  @action
  public finalize = (): void => {
    this.data.clear();
  };

  @action
  public onSelectRow = (order: IOrder): void => {
    this.selectedOrderId = order.id;
  };

  @computed
  public get selectedOrder(): IOrder | undefined {
    return this.data.find((item) => item.id === this.selectedOrderId);
  }

  public create = async (newOrder: Partial<IOrder>): Promise<void> => {
    await this.services.orders.create(newOrder);
  };
}
