import { makeObservable, observable, runInAction } from 'mobx';

import { PurposeType } from '../constants';
import { Services } from '../services';
import { IPackage } from '../view-models';

export class DiagnosticStore {
  @observable public data: any = null;

  public constructor(private services: Services) {
    makeObservable(this);
  }

  public fetch = async (packageId: string, purposeType: PurposeType) => {
    const res = await this.services.diagnostic.get(packageId, purposeType);
    runInAction(() => {
      this.data = res ? observable(res) : null;
    });
  };

  public create = async (pack: IPackage, data: any): Promise<any> => {
    return await this.services.diagnostic.update(pack, data);
  };
}
