import { services } from '../services';

import { AuthStore } from './auth-store';
import { DiagnosticStore } from './diagnostic-store';
import { DictionaryStore } from './dictionary-store';
import { MeasurementStore } from './measurement-store';
import { OrderFormStore } from './order-form-store';
import { OrdersListStore } from './orders-list-store';
import { PackageStore } from './package-store';
import { PackagesListStore } from './packages-list-store';

export const stores = {
  authStore: new AuthStore(services),
  diagnosticStore: new DiagnosticStore(services),
  dictionaryStore: new DictionaryStore(services),
  measurementStore: new MeasurementStore(services),
  orderFormStore: new OrderFormStore(services),
  ordersListStore: new OrdersListStore(services),
  packageStore: new PackageStore(services),
  packagesListStore: new PackagesListStore(services),
};

export type Stores = typeof stores;
