import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import * as lodash from 'lodash';
import { reaction, runInAction } from 'mobx';
import { MobXProviderContext, observer } from 'mobx-react';

import {
  Button,
  Form,
  FormButtons,
  FormFieldType,
  FormWrapper,
  IMetadataField,
  InfoBlock,
  PageWrapper,
  Panel,
  UnderlinedHeader,
} from '../../components';
import { PackageState, packageStateMap, PackageType } from '../../constants';
import { Stores } from '../../stores';
import { message, useConfirm } from '../../utils';
import { IDictionaryItem, IPackage } from '../../view-models';

import { Worksheet } from './worksheet';

import './package-form.css';

export const PackageForm: React.FC = observer(() => {
  const { packageStore: store, dictionaryStore } = React.useContext(MobXProviderContext) as Stores;
  const navigate = useNavigate();
  const { orderId, packageId } = useParams();
  const { confirm } = useConfirm();

  const isCreateMode: boolean = React.useMemo(() => {
    return packageId === 'create';
  }, [packageId]);

  const isEditMode: boolean = React.useMemo(() => {
    return !!packageId && packageId !== 'create';
  }, [packageId]);

  const {
    clearErrors,
    control,
    formState: { defaultValues, errors, isDirty },
    getValues,
    handleSubmit,
    reset,
    resetField,
    setValue,
    watch,
  } = useForm<Partial<IPackage>>({
    values: {},
  });

  const state = watch('state');
  const packageType = watch('type');
  const additionalWorkScope = watch('additionalWorkScope');

  const isDisabledForm: boolean = React.useMemo(() => {
    return state === PackageState.Done;
  }, [state]);

  React.useEffect(() => {
    const dataObserver = reaction(
      () => store.data,
      (pack) => {
        if (pack) {
          reset({ ...pack });
        }
      },
      {
        fireImmediately: true, // чтобы reaction срабатывал и в первый раз
        equals: (prev: IPackage | null, next: IPackage | null): boolean => prev === next,
        delay: 500,
      }
    );

    return () => {
      dataObserver?.();
    };
  }, [store, reset]);

  React.useEffect(() => {
    if (isEditMode) {
      store.init(packageId);
    }
  }, [store, packageId, isEditMode]);

  React.useEffect(() => {
    const type = dictionaryStore.getFirstValue('consul/package-type') as PackageType;
    const cut = dictionaryStore.getFirstValue('consul/cut');
    // const certificationDocumentType = dictionaryStore.getFirstValue('consul/certification-document-type');
    const certificationDocumentType = 'SmallCertificateOfConformity';
    const container = dictionaryStore.getFirstValue('consul/container');

    if (isCreateMode) {
      reset({
        type,
        cut: `${cut}`,
        certificationDocumentType: `${certificationDocumentType}`,
        container: `${container}`,
      });
    }
  }, [
    isCreateMode,
    dictionaryStore.dictionaries.get('consul/package-type'),
    dictionaryStore.dictionaries.get('consul/cut'),
    dictionaryStore.dictionaries.get('consul/container'),
  ]);

  React.useEffect(() => {
    clearErrors('customEngravingText');
  }, [additionalWorkScope?.has('CustomEngraving'), clearErrors]);

  const onChangeCheckboxGroup = React.useCallback(
    (fieldName: keyof Pick<IPackage, 'certificationSystem' | 'additionalWorkScope'>, value: string) => {
      runInAction(() => {
        // new Set надо делать чтобы поменять ссылку на множество и тем самым сказать react-hook-form что поле изменилось
        const currentSet: Set<string> = new Set(getValues(fieldName));
        if (currentSet.has(value)) {
          currentSet.delete(value);
        } else {
          currentSet.add(value);
        }

        const target = currentSet.size > 0 ? currentSet : null;
        const originalValue = lodash.get(defaultValues, fieldName);
        const shouldDirty = !lodash.isEqual(originalValue, target);

        if (shouldDirty) {
          setValue(fieldName, target, {
            shouldValidate: !!lodash.get(errors, fieldName),
            shouldDirty,
          });
        } else {
          resetField(fieldName);
        }
      });
    },
    [getValues, setValue, errors, defaultValues, resetField]
  );

  const changeCertificationSystemHandler = React.useCallback(
    (event: any) => {
      onChangeCheckboxGroup('certificationSystem', event.target.name);
    },
    [onChangeCheckboxGroup]
  );

  const changeAdditionalWorkScopeHandler = React.useCallback(
    (event: any) => {
      onChangeCheckboxGroup('additionalWorkScope', event.target.name);
    },
    [onChangeCheckboxGroup]
  );

  const changePackageTypeHandler = React.useCallback((event: any) => {
    setValue(event.target.name, event.target.value, { shouldValidate: true, shouldDirty: true });

    if (event.target.value === PackageType.MultipleStones) {
      setValue('additionalWorkScope', undefined, { shouldDirty: true });
      setValue('customEngravingText', undefined, { shouldDirty: true });
      setValue('certificationDocumentType', 'SmallCertificateOfConformity', { shouldDirty: true });
      setValue('container', 'PaperBag', { shouldDirty: true });
    }

    if (event.target.value === PackageType.SingleStone) {
      setValue('quantityFact', undefined, { shouldDirty: true });
      setValue('quantityPlan', undefined, { shouldDirty: true });
    }
  }, []);

  const isCertificationDocumentTypeDisabled = React.useCallback(
    (item: IDictionaryItem) => {
      return packageType === PackageType.MultipleStones && !['SmallCertificateOfConformity'].includes(item?.id as string);
    },
    [packageType]
  );

  const isContainerDisabled = React.useCallback(
    (item: IDictionaryItem) => {
      return packageType === PackageType.MultipleStones && !['PaperBag'].includes(item?.id as string);
    },
    [packageType]
  );

  const metadata: IMetadataField[] = React.useMemo(
    () => [
      {
        name: 'type',
        label: 'Тип пакета',
        type: FormFieldType.SELECT,
        url: 'consul/package-type',
        rules: { required: 'Обязательно к заполнению' },
        disabled: isEditMode,
        onChange: changePackageTypeHandler,
      },
      {
        name: 'cut',
        label: 'Вид огранки',
        type: FormFieldType.SELECT,
        url: 'consul/cut',
        rules: { required: 'Обязательно к заполнению' },
      },
      {
        name: 'weightFact',
        label: 'Вес фактический, ct',
        type: FormFieldType.NUMBER,
        rules: { required: 'Обязательно к заполнению' },
      },
      { name: 'weightPlan', label: 'Вес заявленный, ct', description: '(при расхождении)', type: FormFieldType.NUMBER },
      ...(packageType === PackageType.MultipleStones
        ? [
            {
              name: 'quantityFact',
              label: 'Кол-во фактическое',
              type: FormFieldType.NUMBER,
              rules: { required: 'Обязательно к заполнению' },
            },
            {
              name: 'quantityPlan',
              label: 'Кол-во заявленное',
              description: '(при расхождении)',
              type: FormFieldType.NUMBER,
            },
          ]
        : []),
      {
        name: 'certificationSystem',
        label: 'Система оценки',
        type: FormFieldType.CHECKBOX_GROUP,
        url: 'consul/certification-system',
        rules: { required: 'Обязательно к заполнению' },
        onChange: changeCertificationSystemHandler,
      },
      {
        name: 'certificationDocumentType',
        label: 'Вид сертификационного документа',
        type: FormFieldType.RADIO,
        url: 'consul/certification-document-type',
        rules: { required: 'Обязательно к заполнению' },
        isFieldHide: isCertificationDocumentTypeDisabled,
      },
      {
        name: 'container',
        label: 'Упаковка',
        type: FormFieldType.RADIO,
        url: 'consul/container',
        rules: { required: 'Обязательно к заполнению' },
        isFieldHide: isContainerDisabled,
      },
      ...(packageType === PackageType.SingleStone
        ? [
            {
              name: 'additionalWorkScope',
              label: 'Дополнительные работы',
              type: FormFieldType.CHECKBOX_GROUP,
              url: 'consul/additional-work-scopes',
              onChange: changeAdditionalWorkScopeHandler,
            },
            {
              name: 'customEngravingText',
              label: '',
              type: FormFieldType.TEXT,
              disabled: !additionalWorkScope?.has('CustomEngraving'),
              rules: { required: additionalWorkScope?.has('CustomEngraving') ? 'Обязательно к заполнению' : false },
            },
          ]
        : []),
    ],
    [
      isEditMode,
      packageType,
      changeCertificationSystemHandler,
      additionalWorkScope?.has('CustomEngraving'),
      isCertificationDocumentTypeDisabled,
    ]
  );

  const onCloseClick = React.useCallback(() => {
    reset();
    navigate('..');
  }, [navigate, reset]);

  const onSubmit: SubmitHandler<Partial<IPackage>> = React.useCallback(
    async (newPackage) => {
      if (!orderId) {
        return;
      }

      let packageId = newPackage?.packageId ?? null;
      if (isEditMode) {
        const isConfirmed = await confirm({
          title: 'Подтвердите',
          content: 'Вы уверены, что хотите изменить свойства пакета?',
        });

        if (!isConfirmed) {
          return;
        }

        await store.update(newPackage);
      } else {
        packageId = await store.create(orderId, newPackage);
      }

      message.success('Пакет успешно сохранен');

      if (packageId) {
        navigate(`/orders/${orderId}/packages/${packageId}`, { replace: true });
      }
    },
    [store, orderId, isEditMode]
  );

  const headerText = React.useMemo(() => {
    if (packageId === 'create') {
      return 'Новый пакет';
    }

    if (store.data?.number === undefined) {
      return 'У пакета нет номера';
    }

    return `Пакет № ${store.data.number}`;
  }, [packageId, store.data?.number]);

  const stateInfo = React.useMemo(() => {
    return [
      {
        label: 'Статус',
        value: packageStateMap.get(state ?? PackageState.InWork),
      },
    ];
  }, [state]);

  return (
    <PageWrapper>
      <UnderlinedHeader>{headerText}</UnderlinedHeader>
      <FormWrapper>
        {isEditMode && <InfoBlock data={stateInfo} />}
        <Panel className="package-form__panel">
          <Form metadata={metadata} control={control} store={dictionaryStore} errors={errors} disabled={isDisabledForm}>
            <FormButtons>
              <Button.Outlined onClick={onCloseClick}>Закрыть</Button.Outlined>
              <Button.Contained onClick={handleSubmit(onSubmit)} disabled={!isDirty}>
                Сохранить
              </Button.Contained>
              <Button.Outlined startIcon={<FileDownloadOutlinedIcon />} disabled>
                Создать по образцу
              </Button.Outlined>
            </FormButtons>
          </Form>
        </Panel>
        {isEditMode && <Worksheet />}
      </FormWrapper>
    </PageWrapper>
  );
});
