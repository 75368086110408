import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { reaction } from 'mobx';
import { MobXProviderContext, observer } from 'mobx-react';

import {
  Button,
  Form,
  FormButtons,
  FormFieldType,
  FormWrapper,
  InfoBlock,
  PageWrapper,
  Panel,
  UnderlinedHeader,
} from '../../components';
import { OrderState, stateMap } from '../../constants';
import { Stores } from '../../stores';
import { message } from '../../utils';
import { IOrder } from '../../view-models';
import { Packages } from '../orders-list/packages';

import './order-form.css';

export const OrderForm: React.FC = observer(() => {
  const { orderFormStore: store, dictionaryStore } = React.useContext(MobXProviderContext) as Stores;
  const navigate = useNavigate();
  const { orderId } = useParams();

  const isCreateMode: boolean = React.useMemo(() => {
    return orderId === 'create';
  }, [orderId]);

  const isEditMode: boolean = React.useMemo(() => {
    return !!orderId && orderId !== 'create';
  }, [orderId]);

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm<Partial<IOrder>>({
    values: {},
  });
  const clientType = watch('clientType');
  const state = watch('state') as OrderState;
  const packages = watch('packages');

  const isDisabledForm: boolean = React.useMemo(() => {
    return state === OrderState.Done;
  }, [state]);

  const changeClientTypeHandler = React.useCallback(
    (event: any) => {
      setValue(event.target.name, event.target.value, { shouldValidate: true, shouldDirty: true });
      setValue('clientId', undefined, { shouldDirty: true });
    },
    [setValue]
  );

  const metadata = React.useMemo(
    () => [
      { name: 'date', label: 'Дата прихода', type: FormFieldType.DATE, rules: { required: 'Обязательно к заполнению' } },
      {
        name: 'clientType',
        label: 'Тип клиента',
        type: FormFieldType.RADIO,
        url: 'consul/client-type',
        rules: { required: 'Обязательно к заполнению' },
        onChange: changeClientTypeHandler,
      },
      {
        name: 'clientId',
        label: 'Клиент',
        placeholder: 'Выберите значение',
        type: FormFieldType.SELECT,
        url: clientType ? `clients?clientType=${clientType}` : undefined,
        rules: { required: 'Обязательно к заполнению' },
        disabled: !clientType,
      },
      {
        name: 'number',
        label: 'Номер заявки или доп. соглашения',
        placeholder: 'Введите номер',
        type: FormFieldType.TEXT,
        rules: { required: 'Обязательно к заполнению' },
      },
    ],
    [clientType, changeClientTypeHandler]
  );

  React.useEffect(() => {
    const dataObserver = reaction(
      () => store.data,
      (order) => reset({ ...order }),
      {
        fireImmediately: true, // чтобы reaction срабатывал и в первый раз
        equals: (prev: IOrder | null, next: IOrder | null): boolean => prev === next,
        delay: 500,
      }
    );

    return () => {
      dataObserver?.();
      store.finalize();
    };
  }, [store, orderId]);

  React.useEffect(() => {
    if (isEditMode) {
      store.init(orderId);
    }
  }, [store, orderId, isEditMode]);

  React.useEffect(() => {
    const clientType = dictionaryStore.getFirstValue('consul/client-type');

    if (isCreateMode) {
      reset({
        clientType: `${clientType}`,
        clientId: undefined,
        number: undefined,
        date: undefined,
      });
    }
  }, [isCreateMode, dictionaryStore.dictionaries.get('consul/client-type')]);

  const onCloseClick = React.useCallback(() => {
    reset();
    navigate('..');
  }, [navigate, reset]);

  const onSubmit: SubmitHandler<Partial<IOrder>> = React.useCallback(
    async (order) => {
      let orderId = order?.id;
      if (isEditMode) {
        await store.update(order);
      } else {
        orderId = await store.create(order);
      }

      message.success('Заявка успешно сохранена');
      navigate(`/orders/${orderId}`, { replace: true });
    },
    [store, isEditMode]
  );

  const headerText = React.useMemo(() => {
    if (isCreateMode) {
      return 'Новая заявка';
    }

    return `Заявка № ${store.data?.number ?? ''}`;
  }, [isCreateMode, store.data?.number]);

  const stateInfo = React.useMemo(() => {
    return [
      {
        label: 'Статус',
        value: stateMap.get(state ?? OrderState.New),
      },
    ];
  }, [state]);

  return (
    <PageWrapper>
      <UnderlinedHeader>{headerText}</UnderlinedHeader>
      <FormWrapper>
        {isEditMode && <InfoBlock data={stateInfo} />}
        <Panel className="order-form__panel">
          <Form metadata={metadata} control={control} store={dictionaryStore} errors={errors} disabled={isDisabledForm}>
            <FormButtons>
              <Button.Outlined onClick={onCloseClick}>Закрыть</Button.Outlined>
              <Button.Contained onClick={handleSubmit(onSubmit)} disabled={!isDirty}>
                Сохранить
              </Button.Contained>
              <Button.Outlined
                startIcon={<FileDownloadOutlinedIcon />}
                onClick={store.downloadDocuments}
                disabled={isCreateMode || !packages?.length}
              >
                Документы
              </Button.Outlined>
            </FormButtons>
          </Form>
        </Panel>
        {isEditMode && <Packages orderId={orderId ?? ''} packages={packages ?? []} />}
      </FormWrapper>
    </PageWrapper>
  );
});
