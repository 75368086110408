import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react';

import '@fontsource/noto-sans';

import { ConfirmationDialog, Message } from '../../components';
import { message } from '../../utils';

import { Header } from './header';

import '@fontsource/noto-sans/400.css';
import '@fontsource/noto-sans/400-italic.css';
import './root.css';

export const Root: React.FC = observer(() => {
  return (
    <div className="width-limiter">
      <Header />
      <Outlet />
      <Message controller={message} />
      <ConfirmationDialog />
    </div>
  );
});
