function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

/**
 * Генерация RFC 4122 version 4 uuid
 * Взято отсюда https://github.com/brandonscript/lodash-uuid/blob/master/index.js
 * @return {string} - UUID
 */
export function uuid(): string {
  let d = Date.now(); // количество миллисекунд, прошедших с 1 января 1970 года 00:00:00 UTC

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.trunc((d + getRandomInt(16)) % 16);
    d = Math.floor(d / 16);

    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}
