import * as React from 'react';

import { ConfirmContext } from './confirm-context';
import { ActionType, IState } from './reducer';

let resolveCallback: null | ((value: boolean | null) => void) = null;

export type ConfirmFunction = (state?: IState) => Promise<boolean | null>;

export function useConfirm(): {
  confirm: ConfirmFunction;
  onConfirm: () => void;
  onCancel: () => void;
  onHide: () => void;
  state: any;
} {
  const [state, dispatch] = React.useContext(ConfirmContext);

  const onConfirm = async (): Promise<void> => {
    // Надо чтобы функция очевидно вернула false, иначе ломаются схему где не описана эта функция например
    // или функция будет возращать поле которое в runtime может быть undefined
    // В общем если надо не зкрыть окно, то постарайся и явно верни false из функции
    if ((await state.beforeConfirm?.()) !== false) {
      closeConfirm();
      resolveCallback?.(true);
    }
  };

  const onCancel = (): void => {
    closeConfirm();
    resolveCallback?.(false);
  };

  const onHide = (): void => {
    closeConfirm();
    resolveCallback?.(null);
  };

  const confirm = (state?: IState): Promise<boolean | null> => {
    dispatch({
      type: ActionType.Show,
      payload: state,
    });

    return new Promise((res) => {
      resolveCallback = res;
    });
  };

  const closeConfirm = (): void => {
    dispatch({
      type: ActionType.Hide,
    });
  };

  return { confirm, onConfirm, onCancel, onHide, state };
}
