import * as React from 'react';
import { createTheme, Modal as MuiModal, Theme, ThemeProvider } from '@mui/material';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { initialState, IState } from '../../utils';
import { Button } from '../buttons';

import { modalTheme } from './modal-theme';

import './modal.css';

export interface IModalProps extends IState {
  className?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  onHide: () => void;
  isShow?: boolean;
}

export const Modal: React.FC<IModalProps> = observer((props) => {
  const { apply, cancel, className, content, isShow, onCancel, onConfirm, onHide, title } = {
    ...initialState,
    ...props,
  };

  const isCancelButtonShow: boolean = React.useMemo(() => !!(onCancel && cancel), [onCancel, cancel]);
  const isConfirmButtonShow: boolean = React.useMemo(() => !!(onConfirm && apply), [onConfirm, apply]);

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      switch (event.code) {
        case 'Escape': {
          if (isCancelButtonShow) {
            onCancel?.();
            event.stopPropagation();
            event.preventDefault();
            return true;
          }
          break;
        }
        case 'Enter': {
          if (isConfirmButtonShow) {
            onConfirm?.();
            event.stopPropagation();
            event.preventDefault();
            return true;
          }
          break;
        }
      }

      return false;
    },
    [isCancelButtonShow, isConfirmButtonShow, onCancel, onConfirm]
  );

  return (
    <ThemeProvider
      theme={(theme: Theme) =>
        createTheme({
          ...theme,
          components: {
            ...theme.components,
            ...modalTheme.components,
          },
        })
      }
    >
      <MuiModal open={isShow ?? false} onClose={onHide} onKeyDown={onKeyDown}>
        <div className={cn('modal__box', className)}>
          <div className="modal__header ">{title}</div>
          <div className="modal__body">{content}</div>
          {(isCancelButtonShow || isConfirmButtonShow) && (
            <div className="modal__button-block">
              {isCancelButtonShow && <Button.Outlined onClick={onCancel}>{cancel}</Button.Outlined>}
              {isConfirmButtonShow && <Button.Contained onClick={onConfirm}>{apply}</Button.Contained>}
            </div>
          )}
        </div>
      </MuiModal>
    </ThemeProvider>
  );
});
