import * as React from 'react';
import classNames from 'classnames';

import { IDictionaryItem } from '../../view-models';

import './tabs.css';

interface ITabsProps {
  className?: string;
  data: IDictionaryItem[];
  selectedId?: IDictionaryItem['id'];
  onSelect?: (tab: IDictionaryItem) => void;
}

export const Tabs: React.FC<ITabsProps> = (props) => {
  const { className, data, onSelect, selectedId } = props;

  return (
    <div className={classNames(className, 'tabs')}>
      {data?.map((tab) => (
        <pre
          key={tab.id}
          onClick={() => onSelect?.(tab)}
          className={classNames('tabs__tab', tab.id === selectedId && 'tabs__tab_selected')}
        >
          {tab.name}
        </pre>
      ))}
    </div>
  );
};
