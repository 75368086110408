import { IApi } from '../utils';

export class DictionaryService {
  public constructor(
    private consulApi: IApi,
    private api: IApi
  ) {}

  public async get(url: string) {
    if (url.startsWith('consul/')) {
      try {
        const consulRes = await this.consulApi.get({
          url: url.replace('consul/', ''),
          query: { raw: true },
        });

        return JSON.parse(consulRes.body);
      } catch (err) {
        console.warn(err);
      }
    } else {
      try {
        const res = await this.api.get({
          url,
        });

        return res.body;
      } catch {
        return [];
      }
    }
  }
}
