import { KeycloakAuthError } from './keycloak-auth-error';

/**
 * Ошибка неправильный пароль, может включать количество оставшихся попыток до блокировки, если включено в настройках kc
 */
export class InvalidPasswordError extends KeycloakAuthError {
  public static predicate(status: number, body: any): boolean {
    return body?.error === 'invalid_password';
  }

  public constructor(status: number, body: any) {
    super(status, body);
    Object.setPrototypeOf(this, InvalidPasswordError.prototype);
  }

  public get remainingTriesCount(): number {
    return this.errorBody.remaining_tries_count;
  }

  public get displayMessage(): string {
    if (this.remainingTriesCount === 0) {
      return 'Превышено максимально количество попыток входа. Обратитесь в службу поддержки.';
    }

    return `${'Неправильный пароль'}${this.remainingTriesCount > 0 ? `. Осталось попыток: ${this.remainingTriesCount}` : ''}`;
  }
}
