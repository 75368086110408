import { KeycloakAuthError } from './keycloak-auth-error';

/**
 * Ошибка пользователь временно заблокирован
 * возникает если пользователь слишком часто пытается аутентифицироваться
 */
export class UserTemporaryDisabledError extends KeycloakAuthError {
  public static predicate(status: number, body: any): boolean {
    return body?.error === 'user_temporary_disabled';
  }

  public constructor(status: number, body: any) {
    super(status, body);
    Object.setPrototypeOf(this, UserTemporaryDisabledError.prototype);
  }
}
