import * as React from 'react';

import { AddPackageButton } from './add-package-button';

import './packages.css';

export const Worksheet: React.FC = () => {
  return (
    <div className="order-list__packages">
      <h2 className="order-list__packages-header">Рабочий лист</h2>
      <AddPackageButton />
    </div>
  );
};
