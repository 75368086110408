export function toFormData(obj: object): FormData {
  const formData = new FormData();
  for (const [name, value] of Object.entries(obj)) {
    if (![null, undefined].includes(value)) {
      if (Array.isArray(value)) {
        for (const item of value) {
          formData.append(name, item);
        }
      } else {
        formData.append(name, value);
      }
    }
  }

  return formData;
}
