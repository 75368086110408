export class UrlHelper {
  /**
   * Генерация строки параметров запроса для последующей вставки в URL
   */
  public static queryString(query: any): string {
    return query
      ? Object.keys(query)
          .map((key) =>
            ![null, undefined, ''].includes(query[key]) ? `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}` : null
          )
          .filter((part: string | null) => part)
          .join('&')
      : '';
  }

  /**
   * Делит query строку и возвращает её в виде объекта
   */
  public static parseQuery(path?: string | null): { [key: string]: string } {
    //eslint-disable-next-line no-nested-ternary
    const [, query] = path?.includes('?') ? path.split('?') : path?.includes('=') ? [null, path] : [null, ''];

    const result: { [key: string]: string } = {};
    if (query) {
      for (const part of query.replace(/^\?/, '').split('&')) {
        const [key, value] = part.split('=', 2);
        result[decodeURIComponent(key)] = decodeURIComponent(value);
      }
    }

    return result;
  }

  public static getUrlAfterLastSeparator(url: string, separator: string = '.'): string {
    return url.substring(url.lastIndexOf(separator) + 1, url.length);
  }

  public static isAbsolute(url?: string | null): boolean {
    return typeof url === 'string' && url.startsWith('http');
  }
}
