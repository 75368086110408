import dayjs from 'dayjs';

import { BackendEnumDevider, PackageType, PurposeType } from '../constants';
import { HttpError, IApi, toDayjs } from '../utils';
import { IPackage } from '../view-models';

export class PackageService {
  public constructor(private api: IApi) {}

  public async getList(purposeType: PurposeType): Promise<IPackage[]> {
    const res = await this.api.get({
      url: `package/list/${purposeType}`,
    });

    return res.body.items;
  }

  public async getBySearch(search?: string): Promise<IPackage | null> {
    let res;
    try {
      res = await this.api.get({
        url: `package/details/by-number/${search}`,
      });
    } catch (error) {
      if (error instanceof HttpError && error.statusCode === 404) {
        return null;
      }
      throw error;
    }

    return res.body;
  }

  public async getById(packageId: string, purposeType?: PurposeType): Promise<IPackage> {
    const res = await this.api.get({
      url: purposeType ? `package/${packageId}/${purposeType}` : `package/details/${packageId}`,
    });

    return {
      ...res.body,
      certificationSystem: res.body.certificationSystem
        ? new Set(res.body.certificationSystem?.split(BackendEnumDevider) ?? [])
        : null,
      additionalWorkScope: res.body.additionalWorkScope
        ? new Set(res.body.additionalWorkScope?.split(BackendEnumDevider) ?? [])
        : null,
      customEngravingText: res.body.customEngravingText ?? '',
    };
  }

  public async create(requestId: string, pack: Partial<IPackage>): Promise<IPackage['packageId'] | null> {
    const url = (
      {
        [PackageType.SingleStone]: 'package/register-singlestone',
        [PackageType.MultipleStones]: 'package/register-multiplestones',
      } as any
    )[pack.type ?? ''];

    if (url) {
      const res = await this.api.post({
        url,
        body: {
          requestId,
          ...pack,
          weightPlan: pack.weightPlan ?? pack.weightFact,
          quantityPlan: pack.quantityPlan ?? pack.quantityFact,
          certificationSystem: Array.from(pack.certificationSystem ?? []).join(BackendEnumDevider) || null,
          additionalWorkScope: Array.from(pack.additionalWorkScope ?? []).join(BackendEnumDevider) || null,
          customEngravingText: pack.additionalWorkScope?.has('CustomEngraving') ? pack.customEngravingText : null,
        },
      });

      return res.body;
    }

    return null;
  }

  public async update(pack: Partial<IPackage>) {
    const url = (
      {
        SingleStone: 'package/update-singlestone',
        MultipleStones: 'package/update-multiplestones',
      } as any
    )[pack.type ?? ''];

    if (url) {
      await this.api.put({
        url,
        body: {
          ...pack,
          weightPlan: pack.weightPlan ?? pack.weightFact,
          quantityPlan: pack.quantityPlan ?? pack.quantityFact,
          certificationSystem: Array.from(pack.certificationSystem ?? []).join(BackendEnumDevider) || null,
          additionalWorkScope: Array.from(pack.additionalWorkScope ?? []).join(BackendEnumDevider) || null,
          customEngravingText: pack.additionalWorkScope?.has('CustomEngraving') ? pack.customEngravingText : null,
        },
      });
    }
  }
}
