import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import lodash from 'lodash';
import { reaction, toJS } from 'mobx';
import { MobXProviderContext, observer } from 'mobx-react';

import { Button, Form, FormButtons, FormFieldType, IMetadataField } from '../../../components';
import { PurposeType } from '../../../constants';
import { Stores } from '../../../stores';

export const Diagnostic: FC = observer(() => {
  const { diagnosticStore: store, dictionaryStore, packageStore } = useContext(MobXProviderContext) as Stores;
  const { packageId, purposeType } = useParams() as { packageId: string; purposeType: PurposeType };
  const navigate = useNavigate();

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
  } = useForm<any>({
    values: {},
  });

  React.useEffect(() => {
    if (packageId && purposeType) {
      store.fetch(packageId, purposeType);
    }
  }, [store, packageId, purposeType]);

  useEffect(() => {
    const dataObserver = reaction(
      () => store.data,
      (diagnosticData) => {
        if (diagnosticData) {
          reset({ ...diagnosticData, AuroraDiamond: undefined });
        }
      },
      {
        fireImmediately: true, // чтобы reaction срабатывал и в первый раз
        equals: (prev: any, next: any): boolean => lodash.isEqual(prev, next),
        delay: 500,
      }
    );

    return () => {
      dataObserver?.();
    };
  }, [store, reset]);

  const metadata: IMetadataField[] = useMemo(
    () => [
      {
        name: 'mineral',
        label: 'Вид минерала',
        type: FormFieldType.SELECT,
        url: 'consul/minerals',
        rules: { required: 'Обязательно к заполнению' },
      },
      {
        name: 'comment',
        label: 'Комментарий',
        type: FormFieldType.TEXT,
        rules: { required: 'Обязательно к заполнению' },
      },
      {
        name: 'AuroraDiamond',
        label: 'Aurora Diamond',
        type: FormFieldType.TEXT,
        disabled: true,
      },
    ],
    []
  );

  const onSubmit: SubmitHandler<Partial<any>> = useCallback(
    async (data) => {
      if (packageStore.data) {
        await store.create(packageStore.data, data);
        store.fetch(packageId, purposeType);
      }
    },
    [store, packageId, purposeType]
  );

  const onCloseClick = useCallback(() => {
    reset();
    navigate('..');
  }, [navigate, reset]);

  return (
    <Form className={'worksheet__diagnostic'} metadata={metadata} control={control} store={dictionaryStore} errors={errors}>
      <FormButtons>
        <Button.Outlined onClick={onCloseClick}>Закрыть</Button.Outlined>
        <Button.Contained onClick={handleSubmit(onSubmit)} disabled={!isDirty}>
          Сохранить
        </Button.Contained>
        <Button.Outlined disabled>Завершить исследование</Button.Outlined>
        <Alert severity="warning">Раздел 'Диагностика' находиться в разработке</Alert>
      </FormButtons>
    </Form>
  );
});
