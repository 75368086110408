import { makeObservable, observable, ObservableMap, runInAction } from 'mobx';

import { Services } from '../services';
import { FieldOptions } from '../view-models';

export class DictionaryStore {
  /**
   * Общее хранилище справочников
   */
  @observable public dictionaries: ObservableMap<string, FieldOptions> = observable.map();

  public constructor(private services: Services) {
    makeObservable(this);
  }

  public async fetchElements(url?: string): Promise<void> {
    if (!url || this.dictionaries.has(url)) {
      return;
    }

    this.dictionaries.delete(url);
    const res = await this.services.dictionary.get(url);
    runInAction(() => {
      this.dictionaries.set(url, res);
    });
  }

  public getFirstValue = (url?: string): string | number | undefined => {
    const first = this.dictionaries.get(url ?? '')?.[0];

    if (typeof first === 'string') {
      return first;
    } else if (typeof first === 'object') {
      return first?.id;
    }
  };
}
