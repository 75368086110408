import * as React from 'react';
import { FormControlLabel, Radio, RadioGroup, RadioGroupProps } from '@mui/material';
import { observer } from 'mobx-react';

import { DictionaryStore } from '../../../../stores/dictionary-store';
import { IDictionaryItem } from '../../../../view-models';

export const FieldRadio: React.ForwardRefExoticComponent<
  Omit<
    RadioGroupProps & {
      url?: string;
      store?: DictionaryStore;
      isFieldHide?: (item: IDictionaryItem) => boolean;
      disabled?: boolean;
    },
    'ref'
  > &
    React.RefAttributes<unknown>
> = observer(
  React.forwardRef((props, ref) => {
    const { disabled, url, store, value, isFieldHide, ...restProps } = props;

    React.useEffect(() => {
      store?.fetchElements(url);
    }, [url, store]);

    return (
      <RadioGroup {...restProps} value={value ?? ''} ref={ref}>
        {store?.dictionaries
          .get(url ?? '')
          ?.filter((item) => !isFieldHide?.(item) ?? true)
          .map((item) => {
            const value = item?.id;
            const label = item?.name;

            return <FormControlLabel key={value} value={value} control={<Radio disabled={disabled} />} label={label} />;
          })}
      </RadioGroup>
    );
  })
);
