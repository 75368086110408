import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import { OutlinedInputProps } from '@mui/material/OutlinedInput/OutlinedInput';
import { observer } from 'mobx-react';

export const FieldText: React.ForwardRefExoticComponent<Omit<OutlinedInputProps, 'ref'> & React.RefAttributes<unknown>> =
  observer(
    React.forwardRef(({ value, ...props }, ref) => {
      return <OutlinedInput {...props} ref={ref} value={value ?? ''} />;
    })
  );
