import * as React from 'react';

import './info-block.css';

interface InfoBlockItem {
  label: string;
  value?: string;
}

interface IProps {
  className?: string;
  data: InfoBlockItem[];
}

export const InfoBlock: React.FC<IProps> = (props) => {
  const { className, data } = props;

  return data ? (
    <div className={`info-block ${className}`}>
      {data.map((item: InfoBlockItem) => (
        <div key={item.label} className="info-block__block">
          <div className="info-block__label">{item.label}</div>
          <div className="info-block__value">{item.value}</div>
        </div>
      ))}
    </div>
  ) : null;
};
