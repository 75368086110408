import * as React from 'react';
import { MenuItem, Select, SelectProps } from '@mui/material';
import { observer } from 'mobx-react';

import { DictionaryStore } from '../../../../stores/dictionary-store';
import { IDictionaryItem } from '../../../../view-models';

export const FieldSelect: React.ForwardRefExoticComponent<
  Omit<
    Omit<SelectProps<string>, 'value' | 'defaultValue'> & {
      url?: string;
      store?: DictionaryStore;
      value: string | IDictionaryItem;
    },
    'ref'
  > &
    React.RefAttributes<unknown>
> = observer(
  React.forwardRef((props, ref) => {
    const { url, store, value, placeholder, ...restProps } = props;

    React.useEffect(() => {
      store?.fetchElements(url);
    }, [url, store]);

    const selectedValue = React.useMemo(() => {
      return typeof value === 'object' ? `${value?.id ?? ''}` : value;
    }, [value]);

    return (
      <Select {...restProps} value={selectedValue ?? ''} ref={ref}>
        {store?.dictionaries.get(url ?? '')?.map((item) => {
          const value = typeof item === 'object' ? item?.id : item;
          const label = typeof item === 'object' ? item?.name : item;

          return (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    );
  })
);
