import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonProps } from '@mui/material/Button/Button';

import { Button } from '../../../../components';

export const CreateOrderButton: React.FC<ButtonProps> = (props) => {
  const navigate = useNavigate();

  const onCreateOrderClick = React.useCallback(() => {
    navigate('/orders/create');
  }, [navigate]);

  return (
    <Button.Contained {...props} onClick={onCreateOrderClick}>
      Новая заявка
    </Button.Contained>
  );
};
