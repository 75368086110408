import { KeycloakAuthError } from './keycloak-auth-error';

/**
 * Ошибка неверное имя пользователя или пароль
 */
export class InvalidGrantError extends KeycloakAuthError {
  public static predicate(status: number, body: any): boolean {
    return body?.error === 'invalid_grant';
  }

  public constructor(status: number, body: any) {
    super(status, body);
    Object.setPrototypeOf(this, InvalidGrantError.prototype);
  }

  public get displayMessage(): string {
    return 'Неверное имя пользователя или пароль';
  }
}
