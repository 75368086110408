import { outlinedInputClasses } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ruRU } from '@mui/x-date-pickers/locales';

export const theme = createTheme(
  {
    palette: {
      primary: { main: '#206a44' },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.variant === 'outlined' && {
              borderColor: '#bababa',
              color: '#333333',
              fontSize: '16px',
            }),
          }),
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: () => ({
            borderRadius: '10px',
            borderColor: '#cccccc',
            color: '#333333',

            [`&:disabled, .${outlinedInputClasses.disabled}`]: {
              backgroundColor: '#fafafa',
              color: '#777777',
              borderColor: '#fafafa',
              borderRadius: '10px',
            },

            [`.${outlinedInputClasses.disabled} ~ .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: '#fafafa',
            },
          }),
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: () => ({
            color: '#777777',
          }),
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            height: '40px',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            padding: '10px',
            fontSize: '14px',
            lineHeight: '19px',
            color: 'inherit',
            borderBottom: 'none',
          },
          head: {
            backgroundColor: '#ffffff',
          },
        },
      },
    },
  },
  ruRU // x-date-pickers translations
);
