import * as React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { OutlinedInputProps } from '@mui/material/OutlinedInput/OutlinedInput';
import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react';

import { VisiblityButton } from '../../../../pages/login/visiblity-button';

export const FieldPassword: React.ForwardRefExoticComponent<Omit<OutlinedInputProps, 'ref'> & React.RefAttributes<unknown>> =
  observer(
    React.forwardRef(({ value, ...props }, ref) => {
      const [isShowPassword, setShowPassword] = React.useState(false);

      return (
        <OutlinedInput
          {...props}
          ref={ref}
          value={value ?? ''}
          type={isShowPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <VisiblityButton isShow={isShowPassword} onToggle={setShowPassword} />
            </InputAdornment>
          }
        />
      );
    })
  );
