import { action, IObservableArray, makeObservable, observable, runInAction } from 'mobx';

import { PurposeType } from '../constants';
import { Services } from '../services';
import { IPackage } from '../view-models';

export class PackagesListStore {
  @observable public data: IObservableArray<IPackage> | null = null;

  public constructor(private services: Services) {
    makeObservable(this);
  }

  @action
  public init = async (purposeType: PurposeType): Promise<void> => {
    const res = await this.services.package.getList(purposeType);
    runInAction(() => {
      if (this.data) {
        this.data.replace(res);
      } else {
        this.data = observable(res);
      }
    });
  };

  public search = async (search?: string): Promise<IPackage | null> => {
    return this.services.package.getBySearch(search);
  };

  @action
  public finalize = (): void => {
    this.data?.clear();
  };
}
