import * as React from 'react';
import MaterialTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { observer } from 'mobx-react';

import { Cell, ICellProps } from './cell';

export interface ITableMetadata extends Omit<ICellProps, 'children' | 'rowData'> {
  header: string;
  fieldName: string;
}

interface IProps {
  metadata: ITableMetadata[];
  data: any[];
  keyField: string;
  selected?: any;
  onDoubleClickRow?: (row: any) => void;
  onClickRow?: (row: any) => void;
}

export const Table: React.FC<IProps> = observer((props) => {
  const { data, keyField, metadata, onClickRow, onDoubleClickRow, selected } = props;

  return (
    <TableContainer sx={{ maxHeight: '100%' }}>
      <MaterialTable stickyHeader>
        <TableHead>
          <TableRow>
            {metadata.map(({ header, fieldName, ...cellProps }) => (
              <TableCell key={header} {...cellProps}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row[keyField]}
              // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              selected={row === selected}
              onClick={() => onClickRow?.(row)}
              onDoubleClick={() => onDoubleClickRow?.(row)}
            >
              {metadata.map(({ header, ...cellProps }) => (
                <Cell key={header} {...cellProps} rowData={row} />
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MaterialTable>
    </TableContainer>
  );
});
