import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Provider as MobXProvider } from 'mobx-react';

import 'dayjs/locale/ru';

import { PrivateRoute } from '../components';
import { Login, Main, OrderForm, OrdersList, PackageForm, PackagesList, Root, SearchPage, Worksheet } from '../pages';
import { stores } from '../stores';
import { ConfirmContextProvider, setGlobalErrorHandler } from '../utils';

import { theme } from './theme';

export const App: React.FC = () => {
  React.useEffect(setGlobalErrorHandler, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
      <ConfirmContextProvider>
        <ThemeProvider theme={theme}>
          <MobXProvider {...stores}>
            <StyledEngineProvider injectFirst>
              <BrowserRouter>
                <Routes>
                  <Route path={'/login'} element={<Login />} />
                  <Route
                    element={
                      <PrivateRoute>
                        <Root />
                      </PrivateRoute>
                    }
                  >
                    <Route index element={<Main />} />
                    <Route path={'/orders'}>
                      <Route index element={<OrdersList />} />
                      <Route path={':orderId'}>
                        <Route index element={<OrderForm />} />
                        <Route path={'packages/:packageId'}>
                          <Route index element={<PackageForm />} />
                          <Route path={'worksheet/:purposeType'} element={<Worksheet />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route path={'/packages/:purposeType'}>
                      <Route index element={<PackagesList />} />
                    </Route>
                    <Route path={'/search'}>
                      <Route index element={<SearchPage />} />
                    </Route>
                  </Route>
                </Routes>
              </BrowserRouter>
            </StyledEngineProvider>
          </MobXProvider>
        </ThemeProvider>
      </ConfirmContextProvider>
    </LocalizationProvider>
  );
};
