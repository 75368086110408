import { IJwtDTO } from '../models';

export function parseToken(encodedString: string): IJwtDTO | null {
  if (!encodedString) {
    return null;
  }

  const base64Url = encodedString.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c: string): string => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}
