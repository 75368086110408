import * as React from 'react';

import { ConfirmContext } from './confirm-context';
import { initialState, reducer } from './reducer';

export const ConfirmContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return <ConfirmContext.Provider value={[state, dispatch]}>{children}</ConfirmContext.Provider>;
};
