import { KeycloakAuthError } from './keycloak-auth-error';

/**
 * Ошибка пользователь заблокирован
 */
export class UserDisabledError extends KeycloakAuthError {
  public static predicate(status: number, body: any): boolean {
    return body?.error === 'user_disabled';
  }

  public constructor(status: number, body: any) {
    super(status, body);
    Object.setPrototypeOf(this, UserDisabledError.prototype);
  }
}
