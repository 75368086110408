const parametersRegExp = /\${(.*?)}/g;

/**
 * Возвращает результат интерполяции строки
 */
export const interpolateTemplateWithParameters = function (
  template: string,
  parameters: any,
  transformFunc: (uriComponent: string | number | boolean) => string = (uriComponent) => `${uriComponent}`
): string {
  // взято с https://stackoverflow.com/a/55594573
  return template.replace(parametersRegExp, (str, key) => {
    const param = parameters[key];
    if ([null, undefined].includes(param)) {
      console.warn(`При интерполяции строки не найден параметр для "${key}".`);
    }

    return transformFunc(param || str);
  });
};
