import * as React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { MobXProviderContext, observer } from 'mobx-react';

import { FormWrapper, PageWrapper, Panel, Table, UnderlinedHeader } from '../../components';
import { PurposeType } from '../../constants';
import { Stores } from '../../stores';
import { IPackage } from '../../view-models';
import { WhiteGreyTableTheme } from '../common';
import { metadata } from '../orders-list/packages/metadata';

import './packages-list.css';

export const PackagesList: React.FC = observer(() => {
  const { packagesListStore: store } = React.useContext(MobXProviderContext) as Stores;
  const { purposeType } = useParams();

  const navigate = useNavigate();

  React.useEffect(() => {
    store.init(purposeType as PurposeType);

    return store.finalize;
  }, []);

  const onDoubleClick = React.useCallback((pack: IPackage) => {
    if (purposeType) {
      navigate(`/orders/${pack?.requestId}/packages/${pack.packageId}/worksheet/${purposeType}`);
    } else {
      navigate(`/orders/${pack?.requestId}/packages/${pack.packageId}`);
    }
  }, []);

  return (
    <PageWrapper>
      <UnderlinedHeader>Пакеты</UnderlinedHeader>
      <FormWrapper>
        {store.data?.length === 0 && <span>Нет ни одного пакета</span>}
        {store.data?.length ? (
          <Panel className="packages-list__panel">
            <ThemeProvider theme={WhiteGreyTableTheme}>
              <Table metadata={metadata} data={store.data} keyField="id" onDoubleClickRow={onDoubleClick} />
            </ThemeProvider>
          </Panel>
        ) : null}
      </FormWrapper>
    </PageWrapper>
  );
});
