import * as React from 'react';
import lodash from 'lodash';

export enum ActionType {
  Show = 'Show',
  Hide = 'Hide',
}

export interface IState {
  title?: string;
  content?: React.ReactNode;
  apply?: string;
  cancel?: string;
  beforeConfirm?(): Promise<boolean>;
  zIndex?: number;
}

export const initialState: IState = {
  title: 'Подтверждение',
  content: 'Подтвердите действие',
  apply: 'Подтвердить',
  cancel: 'Отменить',
};

export const reducer = (
  state: IState = initialState,
  action: { type: ActionType; payload?: IState }
): IState & { isShow?: boolean } => {
  switch (action.type) {
    case ActionType.Show:
      return {
        isShow: true,
        ...lodash.merge({}, initialState, action.payload),
      };
    default:
      return initialState;
  }
};
