import * as React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import { IMessage, MessageController, MessageType } from '../../utils';

import './message.css';

export interface IMessageProps {
  controller: MessageController;
}

export const Message: React.FunctionComponent<IMessageProps> = observer((props) => {
  return (
    <div className="message">
      {props.controller?.messages.map((item: IMessage) => {
        return (
          <pre
            className={classNames('message__window', {
              ['message__window_error']: item.type === MessageType.Error,
              ['message__window_success']: item.type === MessageType.Success,
              ['message__window_info']: item.type === MessageType.Info,
              ['message__window_warning']: item.type === MessageType.Warning,
              ['message__window_message']: item.type === MessageType.Message,
            })}
            key={item.key}
          >
            {item.payload}
          </pre>
        );
      })}
    </div>
  );
});
