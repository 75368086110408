import * as React from 'react';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import * as lodash from 'lodash';
import { observer } from 'mobx-react';

export interface ICellProps extends Omit<TableCellProps, 'children'> {
  rowData: any;
  fieldName: string;
  template?: (data: any, props: ICellProps) => React.ReactNode;
}

export const Cell: React.FC<ICellProps> = observer((props) => {
  const { rowData, fieldName, template, ...cellProps } = props;

  let content = lodash.get(rowData, fieldName);

  if (template) {
    content = template(rowData, props);
  }

  return <TableCell {...cellProps}>{content}</TableCell>;
});
