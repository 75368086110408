import * as React from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import cn from 'classnames';
import { MobXProviderContext } from 'mobx-react';

import { PurposeType } from '../../../../constants';
import { Stores } from '../../../../stores';
import { message } from '../../../../utils';

import './search.css';

const SearchField = styled(TextField)(({ fullWidth }) => ({
  maxWidth: fullWidth ? '2000px' : '140px',
  width: '100%',
  transition: 'max-width 1s ease-out',

  '& .MuiOutlinedInput-root': {
    borderRadius: '25px',
    height: '50px',
  },
  '*:focus-within > &': {
    maxWidth: '2000px',
  },
}));

interface ISearchProps {
  type: 'orders' | 'packages';
}

export const Search: React.FC<ISearchProps> = (props) => {
  const { packagesListStore } = React.useContext(MobXProviderContext) as Stores;
  const { purposeType } = useParams();

  const { pathname } = useLocation();
  const isSearchPage = React.useMemo(() => pathname === `/search`, [pathname]);

  const [isOpen, setIsOpen] = React.useState<boolean>(isSearchPage ?? false);

  const [type, setType] = React.useState(props.type);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const ref = React.useRef<HTMLDivElement | null>(null);

  const searchValue = React.useMemo(() => searchParams.get(type) ?? '', [searchParams.get(type)]);

  const onFocus = React.useCallback(() => {
    setIsOpen(true);
  }, []);

  const onSearchOrder = React.useCallback(
    (search: string) => {
      if (search) {
        navigate(`/search?${type}=${search}`);
      }
    },
    [navigate, type]
  );

  const onSearchPackage = React.useCallback(
    async (search: string) => {
      if (search) {
        const pack = await packagesListStore.search(search);

        if (!pack) {
          message.warning('Нет такого пакета');
        } else {
          if (purposeType) {
            navigate(`/orders/${pack?.requestId}/packages/${pack.packageId}/worksheet/${purposeType}`);
          } else {
            navigate(`/orders/${pack?.requestId}/packages/${pack.packageId}`);
          }
        }
      }
    },
    [packagesListStore, purposeType]
  );

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.code === 'Enter') {
        const value = (event.target as HTMLInputElement).value;

        if (type === 'orders') {
          onSearchOrder(value);
        } else if (type === 'packages') {
          onSearchPackage(value);
        }
      }
    },
    [type, onSearchOrder, onSearchPackage]
  );

  const onClearClick = React.useCallback(() => {
    const input = ref.current?.getElementsByTagName('input')?.[0];
    if (input) {
      input.value = '';
    }
    setIsOpen(false);
  }, [ref, setIsOpen]);

  React.useEffect(() => {
    if (!isSearchPage) {
      onClearClick();
    }
  }, [isSearchPage, onClearClick]);

  const onChangeType = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setType(event.target.value as 'orders' | 'packages');
    },
    [setType]
  );

  return (
    <>
      <SearchField
        ref={ref}
        defaultValue={searchValue}
        fullWidth={isOpen}
        className="search"
        placeholder="Поиск"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: isOpen && (
            <InputAdornment position="end">
              <CloseOutlinedIcon fontSize="small" onClick={onClearClick} />
            </InputAdornment>
          ),
        }}
        variant="outlined"
        onFocus={onFocus}
        onKeyDown={onKeyDown}
      />
      {isOpen && (
        <RadioGroup
          row
          value={type}
          onChange={onChangeType}
          classes={{
            root: cn({
              search__types: true,
              search__types_show: !isSearchPage && isOpen,
              search__types_hide: !isSearchPage && !isOpen,
            }),
          }}
        >
          <FormControlLabel value={'orders'} control={<Radio />} label="Заявкa" />
          <FormControlLabel value={'packages'} control={<Radio />} label="Пакет" />
        </RadioGroup>
      )}
    </>
  );
};
