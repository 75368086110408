import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import lodash from 'lodash';
import { MobXProviderContext, observer } from 'mobx-react';

import { FormWrapper, PageWrapper, Panel, Table, UnderlinedHeader } from '../../components';
import { OrderState } from '../../constants';
import { Stores } from '../../stores';
import { IOrder } from '../../view-models';
import { WhiteGreyTableTheme } from '../common';

import { metadata } from './metadata';
import { Packages } from './packages';

import './orders-list.css';

export const OrdersList: React.FC = observer(() => {
  const { ordersListStore: store } = React.useContext(MobXProviderContext) as Stores;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    store.init(searchParams.get('search') ?? '');

    return store.finalize;
  }, [searchParams.get('search')]);

  const onDoubleClick = React.useCallback((order: IOrder) => {
    navigate(`/orders/${order?.id}`);
  }, []);

  const totalInfo = React.useMemo(() => {
    return {
      totalCount: store.data?.length,
      inWorkCount: lodash.filter(store.data, { state: OrderState.InWork }).length ?? 0,
      DoneCount: lodash.filter(store.data, { state: OrderState.Done }).length ?? 0,
    };
  }, [store.data?.length]);

  return (
    <PageWrapper>
      <UnderlinedHeader>Заявки</UnderlinedHeader>
      <FormWrapper>
        <Panel className="order-list__panel">
          <ThemeProvider theme={WhiteGreyTableTheme}>
            <Table
              metadata={metadata}
              data={store.data}
              keyField="id"
              onDoubleClickRow={onDoubleClick}
              onClickRow={store.onSelectRow}
              selected={store.selectedOrder}
            />
          </ThemeProvider>
          <div className="order-list__total-row">
            <span>Всего:</span>
            <span>{`заявок: ${totalInfo.totalCount}, в работе: ${totalInfo.inWorkCount}, завершено: ${totalInfo.DoneCount}`}</span>
          </div>
        </Panel>
        {store.selectedOrder && <Packages orderId={store.selectedOrder.id} packages={store.selectedOrder.packages ?? []} />}
      </FormWrapper>
    </PageWrapper>
  );
});
