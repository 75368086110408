import { PurposeType } from '../constants';
import { IApi } from '../utils';
import { IDiagnostic, IPackage } from '../view-models';

export class DiagnosticService {
  public constructor(private api: IApi) {}

  public async get(packageId: string, purposeType: PurposeType): Promise<IDiagnostic> {
    const res = await this.api.get({
      url: `package/${packageId}/${purposeType}`,
    });

    return res.body?.worksheet?.workItems?.diagnostics;
  }

  public async update(pack: IPackage, body: any) {
    await this.api.put({
      url: 'worksheet/diagnostics',
      body: {
        number: pack.number,
        ...body,
      },
    });
  }
}
