import { Http } from './http';
import { IApi, IRequestParamsDTO, IServerResponseDTO } from './models';
import { toJSONPatch } from './to-json-patch';

/**
 * Класс для более удобный работы с конкретным API сервисом
 */
export class Api implements IApi {
  private http: Http;

  /**
   * Создание экземпляра этого класса привязанного к конкретному API-сервису
   */
  public constructor(
    private _apiBaseUrl: string,
    isPrivate?: boolean
  ) {
    this.http = new Http(_apiBaseUrl, isPrivate);
  }

  public setApiBaseUrl = (apiBaseUrl: string) => {
    this.http.setApiBaseUrl(apiBaseUrl);
  };

  public get apiBaseUrl(): Http['apiBaseUrl'] {
    return this.http.apiBaseUrl;
  }

  public get(params: IRequestParamsDTO): Promise<IServerResponseDTO> {
    return this.http.get.apply(this.http, [params]);
  }

  public post(params: IRequestParamsDTO): Promise<IServerResponseDTO> {
    return this.http.post.apply(this.http, [params]);
  }

  public put(params: IRequestParamsDTO): Promise<IServerResponseDTO> {
    return this.http.put.apply(this.http, [params]);
  }

  public patch(params: IRequestParamsDTO): Promise<IServerResponseDTO> {
    return this.http.patch.apply(this.http, [params]);
  }

  public jsonPatch(params: IRequestParamsDTO): Promise<IServerResponseDTO> {
    params.body = toJSONPatch(params.body);

    return this.http.patch.apply(this.http, [params]);
  }

  public del(params: IRequestParamsDTO): Promise<IServerResponseDTO> {
    return this.http.del.apply(this.http, [params]);
  }
}
