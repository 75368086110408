import { FC, useCallback, useContext, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { MobXProviderContext, observer } from 'mobx-react';

import { Button, Form, FormButtons, FormFieldType, IMetadataField } from '../../../components';
import { Stores } from '../../../stores';

export const Gemology: FC = observer(() => {
  const { dictionaryStore } = useContext(MobXProviderContext) as Stores;
  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<any>({
    values: {},
  });

  const metadata: IMetadataField[] = useMemo(
    () => [
      {
        name: 'type',
        label: 'Натуральность происхождения',
        type: FormFieldType.SELECT,
        url: 'consul/package-type',
        rules: { required: 'Обязательно к заполнению' },
      },
      {
        name: 'Aurora Diamond',
        label: 'Фантазийная форма',
        type: FormFieldType.TEXT,
        rules: { required: 'Обязательно к заполнению' },
      },
      // Форма ограники РФ
      {
        name: 'Aurora Diamond',
        label: 'Наименование',
        type: FormFieldType.SELECT,
        rules: { required: 'Обязательно к заполнению' },
      },
      {
        name: 'Aurora Diamond',
        label: 'Форма ограники',
        type: FormFieldType.SELECT,
        rules: { required: 'Обязательно к заполнению' },
      },
      // Форма ограники GIA
      {
        name: 'Aurora Diamond',
        label: 'Наименование',
        type: FormFieldType.SELECT,
        rules: { required: 'Обязательно к заполнению' },
      },
      {
        name: 'Aurora Diamond',
        label: 'Стиль ограники',
        type: FormFieldType.SELECT,
        rules: { required: 'Обязательно к заполнению' },
      },
      // -----
      {
        name: 'Aurora Diamond',
        label: 'Огранка',
        type: FormFieldType.SELECT,
        rules: { required: 'Обязательно к заполнению' },
      },
      {
        name: 'Aurora Diamond',
        label: 'Симметрия',
        type: FormFieldType.SELECT,
        rules: { required: 'Обязательно к заполнению' },
      },
      {
        name: 'Aurora Diamond',
        label: 'Каллетта',
        type: FormFieldType.SELECT,
        rules: { required: 'Обязательно к заполнению' },
      },
      {
        name: 'Aurora Diamond',
        label: 'Полировка',
        type: FormFieldType.SELECT,
        rules: { required: 'Обязательно к заполнению' },
      },
      {
        name: 'comment',
        label: 'Комментарий',
        type: FormFieldType.TEXT,
        rules: { required: 'Обязательно к заполнению' },
      },
    ],
    []
  );

  const onSubmit: SubmitHandler<Partial<any>> = useCallback(async (data) => {
    console.warn(data);
  }, []);

  const onCloseClick = useCallback(() => {
    reset();
    navigate('..');
  }, [navigate, reset]);

  return (
    <Form
      className={'worksheet__diagnostic'}
      metadata={metadata}
      control={control}
      store={dictionaryStore}
      errors={errors}
      disabled
    >
      <FormButtons>
        <Button.Outlined onClick={onCloseClick}>Закрыть</Button.Outlined>
        <Button.Contained onClick={handleSubmit(onSubmit)} disabled>
          Сохранить
        </Button.Contained>
        <Button.Outlined disabled>Завершить исследование</Button.Outlined>
        <Alert severity="warning">Раздел 'Геммологическая экспертиза' находиться в разработке</Alert>
      </FormButtons>
    </Form>
  );
});
