import { ITableMetadata } from '../../../components';
import { packageStateMap } from '../../../constants';

export const metadata: ITableMetadata[] = [
  {
    header: 'НОМЕР',
    fieldName: 'number',
    align: 'right',
  },
  {
    header: 'КОЛ-ВО',
    fieldName: 'quantityFact',
    align: 'right',
  },
  {
    header: 'ВЕС',
    fieldName: 'weightFact',
    align: 'right',
  },
  {
    header: 'СТAТУС',
    fieldName: 'state',
    align: 'right',
    template: (data, props) => {
      const state = data[props.fieldName];
      return packageStateMap.get(state) ?? state;
    },
  },
];
