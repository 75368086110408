import * as React from 'react';
import cn from 'classnames';
import lodash from 'lodash';

import { Field, IFieldProps } from './field';

import './form.css';

export interface IMetadataField extends Omit<IFieldProps, 'control' | 'store'> {}

interface IProps extends Pick<IFieldProps, 'control' | 'errors' | 'rules' | 'store' | 'disabled'> {
  children?: React.ReactNode;
  className?: string;
  metadata: Array<IMetadataField>;
  disabled?: boolean;
}

export const Form: React.FC<IProps> = (props) => {
  const { className, children, metadata, disabled, ...restProps } = props;

  return metadata ? (
    <form className={cn(className, 'form')}>
      {metadata.map((item: IMetadataField) => (
        <React.Fragment key={item.name}>
          {!lodash.isNil(item.label) && (
            <div className="form__label">
              <div>{item.label}</div>
              {item.description && <div className="form__description">{item.description}</div>}
            </div>
          )}
          <Field {...restProps} {...item} disabled={disabled || item.disabled || false} />
        </React.Fragment>
      ))}
      <div className="form__footer">{children}</div>
    </form>
  ) : null;
};
