import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { MobXProviderContext, observer } from 'mobx-react';

import { Breadcrumbs } from '../../../components';
import { Stores } from '../../../stores';

import { CreateOrderButton } from './create-order-button';
import { Search } from './search-button';

import './header.css';

export const Header: React.FC = observer(() => {
  const { orderFormStore: store } = React.useContext(MobXProviderContext) as Stores;

  const { pathname } = useLocation();
  const { orderId } = useParams();

  React.useEffect(() => {
    if (pathname.match(/^\/orders\/\S+\//) && orderId && orderId !== 'create') {
      store.init(orderId);
    }
  }, [pathname, store, orderId]);

  const isOrdersListPage = React.useMemo(() => pathname === `/orders`, [pathname]);
  const isOrderFormPage = React.useMemo(() => pathname === `/orders/${orderId}`, [pathname, orderId]);

  const isPackagesListPage = React.useMemo(() => pathname.startsWith(`/packages`), [pathname]);
  const isSearchPage = React.useMemo(() => pathname === `/search`, [pathname]);

  const isShowSearchButton = React.useMemo(
    () => isOrdersListPage || isPackagesListPage || isSearchPage,
    [isOrdersListPage, isPackagesListPage, isSearchPage]
  );
  const isShowCreateOrderButton = React.useMemo(() => isOrdersListPage || isOrderFormPage, [isOrdersListPage, isOrderFormPage]);

  const breadcrumbs = React.useMemo(() => {
    const result = [];

    if (pathname.match(/^\/.+/)) {
      result.push({ label: 'Главная', href: '/' });
    }

    if (pathname.match(/^\/orders\/.+/)) {
      result.push({ label: 'Заявки', href: '/orders' });
    }

    if (pathname.match(/^\/orders\/\S+\//) && store.data) {
      result.push({ label: `Заявка № ${store.data.number}`, href: `/orders/${store.data.id}` });
    }

    return result;
  }, [pathname, orderId, store.data?.id]);

  return (
    <div className="header">
      <Breadcrumbs data={breadcrumbs} />
      <div className="header__button-block">
        {isShowSearchButton && <Search type={isPackagesListPage ? 'packages' : 'orders'} />}
        {isShowCreateOrderButton && <CreateOrderButton className="header__create-order-button" />}
        <AccountCircleOutlinedIcon fontSize="large" />
      </div>
    </div>
  );
});
