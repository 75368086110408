import { message } from './message-controller';

/**
 * Функция для установки глобальных обработчиков ошибок
 */
export const setGlobalErrorHandler = () => {
  // глобальный обработчик асинхронных ошибок
  window.addEventListener('unhandledrejection', (event) => {
    // ?. нужен потому что в reason может быть null/undefined
    const displayMessage = event.reason?.displayMessage || event.reason?.message;
    // if нужен потому в reason будет то что передадут в Promise.reject(), а это необязательно Error
    if (typeof displayMessage === 'string') {
      message.error(displayMessage);
    }

    console.error(event.reason);
  });

  let prevSyncError: Error | null = null;
  // глобальный обработчик синхронных ошибок
  window.addEventListener('error', (event) => {
    // if это костыль чтобы убрать повторный выброс той же ошибки React-ом ради ErrorBoundary https://github.com/facebook/react/issues/11499
    if (event.error !== prevSyncError) {
      message.error(event.error.displayMessage || event.error.message);
      console.error(event.error);

      prevSyncError = event.error;
    }
  });
};
