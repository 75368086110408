import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../../../components';

import './add-package-button.css';

export const AddPackageButton: React.FC = () => {
  const navigate = useNavigate();

  const onClick = React.useCallback(() => {
    navigate(`worksheet`);
  }, [navigate]);

  return (
    <Button.Outlined onClick={onClick} className="add-package-button">
      Перейти
    </Button.Outlined>
  );
};
