import * as React from 'react';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import lodash from 'lodash';
import { reaction } from 'mobx';
import { MobXProviderContext, observer } from 'mobx-react';

import { Button, Form, FormButtons, FormFieldType, IMetadataField } from '../../../components';
import { PackageType, PurposeType } from '../../../constants';
import { Stores } from '../../../stores';
import { IPackage } from '../../../view-models';

export const Measurement: React.FC = observer(() => {
  const { dictionaryStore, measurementStore: store, packageStore } = useContext(MobXProviderContext) as Stores;
  const { packageId, purposeType } = useParams() as { packageId: string; purposeType: PurposeType };
  const navigate = useNavigate();

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
  } = useForm<any>({
    values: {},
  });

  React.useEffect(() => {
    if (packageId && purposeType) {
      store.fetch(packageId, purposeType);
    }
  }, [store, packageId, purposeType]);

  useEffect(() => {
    const dataObserver = reaction(
      () => store.data,
      (data) => {
        if (data) {
          reset({ ...data });
        }
      },
      {
        fireImmediately: true, // чтобы reaction срабатывал и в первый раз
        equals: (prev: any, next: any): boolean => lodash.isEqual(prev, next),
        delay: 500,
      }
    );

    return () => {
      dataObserver?.();
    };
  }, [store, reset]);

  const onCloseClick = useCallback(() => {
    reset();
    navigate('..');
  }, [navigate, reset]);

  const onSubmit: SubmitHandler<Partial<any>> = useCallback(
    async (data) => {
      if (packageStore.data) {
        await store.create(packageStore.data, data);
        store.fetch(packageId, purposeType);
      }
    },
    [store, packageId, purposeType]
  );

  const pack: IPackage | null = useMemo(() => packageStore.data, [packageStore.data]);

  const metadata: IMetadataField[] = useMemo(
    () => [
      {
        name: 'minDiameterMm',
        label: 'Диаметр Dmin',
        type: FormFieldType.NUMBER,
        rules: { required: 'Обязательно к заполнению' },
      },
      {
        name: 'maxDiameterMm',
        label: 'Диаметр Dmax',
        type: FormFieldType.NUMBER,
        rules: { required: 'Обязательно к заполнению' },
      },
      ...(pack?.type === PackageType.MultipleStones
        ? [
            {
              name: 'minHeightMm',
              label: 'Высота Hmin',
              type: FormFieldType.NUMBER,
              rules: { required: 'Обязательно к заполнению' },
            },
            {
              name: 'maxHeightMm',
              label: 'Высота Hmax',
              type: FormFieldType.NUMBER,
              rules: { required: 'Обязательно к заполнению' },
            },
          ]
        : []),
      ...(pack?.type === PackageType.SingleStone
        ? [
            {
              name: 'totalHeightMm',
              label: 'Общая высота, мм',
              type: FormFieldType.NUMBER,
            },
            {
              name: 'totalDepthPt',
              label: 'Общая высота, %',
              type: FormFieldType.NUMBER,
            },
            {
              name: 'tableSizePt',
              label: 'Размер площадки, %',
              type: FormFieldType.NUMBER,
            },
            {
              name: 'crownHeightPt',
              label: 'Высота короны, %',
              type: FormFieldType.NUMBER,
            },
            {
              name: 'pavilionDepthPt',
              label: 'Глубина павильона, %',
              type: FormFieldType.NUMBER,
            },
            {
              name: 'girdleThicknessPt',
              label: 'Высота рундиста, %',
              type: FormFieldType.NUMBER,
            },
            {
              name: 'crownAngleDg',
              label: 'Угол короны, град',
              type: FormFieldType.NUMBER,
            },
            {
              name: 'pavilionAngleDg',
              label: 'Угол павильона, град',
              type: FormFieldType.NUMBER,
            },
            {
              name: 'lowerHalfLengthPt',
              label: 'Высота клиньев низа, %',
              type: FormFieldType.NUMBER,
            },
            {
              name: 'starLengthPt',
              label: 'Высота клиньев верха, %',
              type: FormFieldType.NUMBER,
            },
          ]
        : []),
    ],
    []
  );

  return (
    <Form className={'worksheet__measurement'} metadata={metadata} control={control} store={dictionaryStore} errors={errors}>
      <FormButtons>
        <Button.Outlined onClick={onCloseClick}>Закрыть</Button.Outlined>
        <Button.Contained onClick={handleSubmit(onSubmit)} disabled={!isDirty}>
          Сохранить
        </Button.Contained>
        <Button.Outlined disabled>Завершить исследование</Button.Outlined>
        <Alert severity="warning">Раздел 'Параметры' находиться в разработке</Alert>
      </FormButtons>
    </Form>
  );
});
