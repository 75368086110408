import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { MobXProviderContext, observer } from 'mobx-react';

import { FormWrapper, PageWrapper, Panel, Table, UnderlinedHeader } from '../../components';
import { Stores } from '../../stores';
import { IOrder } from '../../view-models';
import { WhiteGreyTableTheme } from '../common';
import { metadata as ordersListMetadata } from '../orders-list/metadata';

import './search-page.css';

export const SearchPage: React.FC = observer(() => {
  const { ordersListStore: store } = React.useContext(MobXProviderContext) as Stores;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const value = searchParams.get('orders') ?? '';

  React.useEffect(() => {
    store.init(value);
    return store.finalize;
  }, [value]);

  const onDoubleClick = React.useCallback(
    (order: IOrder) => {
      navigate(`/orders/${order.id}`);
    },
    [navigate]
  );

  return (
    <PageWrapper>
      <UnderlinedHeader>{'Заявки'}</UnderlinedHeader>
      <FormWrapper>
        <Panel className="search-page__panel">
          <ThemeProvider theme={WhiteGreyTableTheme}>
            <Table metadata={ordersListMetadata} data={store.data} keyField="id" onDoubleClickRow={onDoubleClick} />
          </ThemeProvider>
        </Panel>
      </FormWrapper>
    </PageWrapper>
  );
});
