/**
 * Исследование
 */
export enum WorkItems {
  Gemology = 'gemology',
  Measurement = 'measurement',
  Diagnostics = 'diagnostics',
  Engraving = 'engraving',
  Shooting = 'shooting',
  Plotting = 'plotting',
}
